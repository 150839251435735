@import '../../assets/styles/global.style.scss';

@media only screen and (max-width : 694px){
    .popup-calendar-main-box{
        top: 20px;
    }
  
}
@media only screen and (max-width : 979px) and (min-width :695px){
    // .popup-calendar-main-box{
    //     width:65%;
    //     top: 25.5%;
    //     right: 19%;
    // }
    
}
@media only screen and (max-width : 2000px) and (min-width :980px){
    .popup-calendar-main-box{
        top: 100px;
    }
   
    
}
.popup-calendar-scrren-saver{
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #525965;
    opacity: 0.4;
    text-align: center;
    z-index: 9999;
    overflow: visible;
}
.popup-calendar-main-box{
    .calendatr{
        z-index: 9999999999;
    }
    position: fixed;
    
    // width: fit-content;
    // height: 100%;
    right: 0;
    left: 0;
    z-index: 10000;
    & > div{
        position: relative;
        width: fit-content;
        margin: auto auto !important;
        background-color: white;
        @extend .card-box-shadow;
        border-radius:5px;

    }
}
