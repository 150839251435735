@import '../../Assets/Styles/values.scss';
@import '../../Assets/Styles/values.scss';
@media only screen and (max-width: 440px) {
    .calendar-year-container{
        width: 300px !important;
        grid-gap: 1px;
    }
    .calendar-month-container{
        width: 300px !important;
        grid-gap: 10px;
    }
    .calendar-day-container{
        width:300px ;
        height: 210px;
        grid-gap: 3px;
    }
    .two-month-container{
        grid-template-columns:  1fr;
        grid-template-rows: 1fr;
        
    }
    
}
@media only screen and (max-width: 694px) and (min-width: 441px) {
    .calendar-year-container{
        width: 250px !important;

        grid-gap: 5px;
    }
    .calendar-month-container{
        width: 250px !important;

        grid-gap: 5px;
    }
    .calendar-day-container{
        width:300px ;

        grid-gap: 3px;
    }
    .two-month-container{
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        
    }
}

@media only screen and (max-width: 1024px) and (min-width: 695px) {
    .calendar-year-container{
        width: 300px !important;
        grid-gap: 5px;
    }
    .calendar-month-container{
        width: 300px !important;
        grid-gap: 5px;
    }
    .calendar-day-container{
        width:350px ;
        grid-gap: 3px;
    }
    .two-month-container{
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        
    }
}
@media only screen and (max-width: 2000px) and (min-width: 1025px) {
    .calendar-year-container{
        width: 350px !important;

        grid-gap: 10px;
    }
    .calendar-month-container{
        width: 350px !important;

        grid-gap: 10px;
    }
    .calendar-day-container{
        width:450px ;
        grid-gap: 3px;
    }
    .two-month-container{
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        
    }
      
}
.two-month-container{
    display: grid;
}
.calendar{
    text-align: center;
    // max-height: 500px;
    width: fit-content;
    overflow: scroll;
    // margin-top: 5px;
    .row{
        span{
            cursor: pointer;
        }
        svg{
            margin-top: 10px;
            cursor: pointer;
        }
    }
}
.calendar-year-container,.calendar-month-container{
    text-align: center;
    display: grid;
    width: 100%;
    height: 300px;
    padding: 10px;
    margin: 0 auto;
    .calendar-item{
        text-align: center;
        border:2px solid $primaryColor;
        height: 47px;
        line-height: 47px;
        border-radius: 5px;
        color: $primaryColor;
        font-size: 14px;
        cursor: pointer;
        &:hover{
            background-color:$primaryColor;
            color: $whiteColor;
        }
    }
}
.calendar-day-container{
    text-align: center;
    display: grid;
    padding: 5px;
    .calendar-item{
        text-align: center;
        border:1px solid #c1c1c1;
        height: 28px;
        line-height: 28px;
        border-radius: 5px;
        color: $blackColor;
        font-size: 14px;
        cursor: pointer;
    }
    .border-bottom-black{
        margin: 10px auto 0 auto;
        padding: 7px;
        width: 85%;
    }  
}

.calendar-year-container{
    grid-template-columns:1fr 1fr 1fr 1fr 1fr;
    grid-template-rows:auto;
}
.calendar-month-container{
    grid-template-columns:1fr 1fr 1fr;
    grid-template-rows:auto;
}
.calendar-day-container{
    grid-template-columns:1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows:auto;
}
.TODAY{
    background-color: #f2f2f2;
    border-radius: 3px;
    border-color:$secondaryColor !important;
}
.BEFORE{
    background-color: #fff;
    border: 1px solid #c1c1c1;
    border-radius: 3px;
    cursor: default !important;
    background-image: repeating-linear-gradient(-45deg,transparent,transparent 8px,#eee 0,#eee 10px)!important;
}
    // .calendarContainer{
//     width:100% !important;
//     .heading{
//         border-bottom: 1px solid black;
//         width:90% !important;
//         margin: auto;
//         button{
//             padding:auto 0 !important;
//             &:hover{
//                 border-radius: 0!important;
//                 background: transparent !important;   
//             }
//             &:nth-child(2){
//                 text-align: right;
//             }
//             &:nth-child(3){
//                 text-align: left;
//             }
//         }
//     }
// }
// .dayWrapper{
//     visibility:hidden;
//     border: solid 1px #c1c1c1 ;
//     border-radius: 3px;
//     width:12.5% !important;
//     margin:2px !important;
//     height: 28px;
//     button{
//         width: 99% !important;
//         height: 100% !important;
//         background: transparent !important;   
//         border-radius: 0!important;
//         border:none !important;
        
//         &:hover{
//             border-radius: 0!important;
//             background: transparent !important;   
//         }
//         &:focus{
//             color:black !important;
//         }
//     }
   
// }
// .currentMonth{
//     visibility: visible !important;
// }
// .disabled{
//     background-image: repeating-linear-gradient(-45deg,transparent,transparent 8px,#eee 0,#eee 10px)!important;
//     button{
//         border-radius: 0 !important;    
//     }
    
// }
// .today_{
//     background-color: #f2f2f2;
//     border-radius: 3px;
//     border-color:$secondaryColor !important;

//     button{
//         background: transparent !important;
//         border-radius: 0px;
//         border:none;
//         outline: none;
//         color:black !important;
//     }
// }
// .selected button{
//     background-color: $whiteColor !important;
//     color:black !important;

// }
// .monthsList,.yearsList{
//     padding: 10px;
//     div{
       
//         button{
//             color:$pillTextGray !important;
//             height: 44px!important;
//             width: 30% !important;
//             margin:5px;
//             border-radius: 5px;
//             border:2px solid $primaryColor !important;
//             &:hover{
//                 background-color: $primaryColor !important;
//                 color:$whiteColor !important;
//             }
//         }
//     }
// }