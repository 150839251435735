@import "values.scss";

// @font-face {
//     font-family: BYekan;
//     src: url("../Font/Yekan/BYekan.woff");
// }

// @font-face {
//     font-family: BYekan;
//     src: url("../Font/IranSans2/eot/IRANSansWeb\(FaNum\)_UltraLight.woff");
// }
// @font-face {
//     font-family: IranBold;
//     src: url("../Font/IranSans2/eot/IRANSansWeb\(FaNum\)_Bold.woff");
// }
// @font-face {
//     font-family: kilo;
//     src: url("../Font/kilofont.woff");
// }
.kilo-font {
  font-family: kilo;
  font-style: normal;
}
@media only screen and (min-width: 1101px) {
  .hidden-lg {
    display: none;
  }
}
@media only screen and (max-width: 1100px) and (min-width: 767px) {
  .hidden-md {
    display: none;
  }
}
@media only screen and (max-width: 768px) and (min-width: 451px) {
  .hidden-sm {
    display: none !important;
  }
  .visible-sm {
    display: flex;
  }
  .sm-font-size-13 {
    font-size: 13px !important;
  }
  .sm-font-size-14 {
    font-size: 14px !important;
  }
  .sm-font-size-11 {
    font-size: 11px !important;
  }
  .sm-font-size-16 {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 450px) {
  .hidden-xs {
    display: none !important;
  }
  .padding-xs-0-7 {
    padding: 0 7px;
  }
  .no-padding-xs {
    padding: 0 !important;
  }
  .no-margin-xs {
    margin: 0 !important;
  }
  .padding-xs-5-15 {
    padding: 5px 15px;
  }
  .padding-xs-5-25 {
    padding: 5px 25px;
  }
  .xs-font-size-13 {
    font-size: 13px !important;
  }
  .xs-font-size-14 {
    font-size: 14px !important;
  }
  .xs-font-size-11 {
    font-size: 11px !important;
  }
  .xs-font-size-16 {
    font-size: 16px !important;
  }
  .visible-xs {
    display: flex !important;
  }

  .border-pill-lg {
    border: none !important;
  }
}

body {
  font-family: BYekan;
  z-index: 1 !important;
  direction: rtl;
  background-color: $primaryColor !important;
}
.ltr {
  direction: ltr !important;
}
.font-bold-iransanse {
  font-family: IranBold !important;
}
a {
  color: $pillTextGray;
  &:hover {
    text-decoration: none;
    color: $secondaryColor;
  }
}
body,
html {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
.no-padding {
  padding: 0 !important;
}
.no-margin {
  margin: 0 !important;
}
.no-margin-vertical {
  margin: 0 auto !important;
}
.no-padding-right {
  padding-right: 0 !important;
}
.no-padding-horizental {
  padding: 5px 0 !important;
}
.no-padding-vertical {
  padding: 0 5px !important;
}
.margin-top-20px {
  margin-top: 20px;
}
.margin-top-15px {
  margin-top: 15px;
}
.margin-top-10px {
  margin-top: 10px;
}
.margin-top-5px {
  margin-top: 5px;
}
.margin-bottom-5px {
  margin-bottom: 5px !important;
}
.margin-bottom-0px {
  margin-bottom: 0px !important;
}

.margin-5px {
  margin: 5px;
}
.padding-5px {
  padding: 5px;
}
.padding-3px {
  padding: 3px;
}
.padding-horizental-3px {
  padding: 0 3px !important;
}
.padding-horizental-10px {
  padding: 0 10px !important;
}
.padding-10px {
  padding: 10px;
}
.card-box-shadow {
  box-shadow: 2px 0px rgba(0, 0, 0, 0), 0 0 8px rgba(152, 152, 152, 0.12);
}
.font-size-10 {
  font-size: 10px;
}
.font-size-11 {
  font-size: 11px;
}
.font-size-12 {
  font-size: 12px;
}
.font-size-13 {
  font-size: 13px;
}
.font-size-14 {
  font-size: 14px;
}
.font-size-15 {
  font-size: 15px;
}
.font-size-16 {
  font-size: 16px;
}
.font-size-18 {
  font-size: 18px;
}
.font-size-20 {
  font-size: 20px;
}
.font-size-22 {
  font-size: 22px;
}
.font-size-23 {
  font-size: 23px;
}
.font-size-24 {
  font-size: 24px;
}
.font-size-25 {
  font-size: 25px;
}
.color-primary {
  color: $primaryColor;
}
.color-textpill {
  color: $pillTextGray;
}
.color-black {
  color: black;
}
.color-white {
  color: white;
}
.background-white {
  background-color: white;
}
.color-secondary {
  color: $secondaryColor;
}
.btn-outlined {
  border: 1px solid $primaryColor;
  padding: 1px 3px 2px 2px;
  cursor: pointer;
  color: black;
  border-radius: 5px;
  &:hover {
    text-decoration: none;
    color: black;
  }
}
.btn-outlined-cancle {
  @extend .btn-outlined;
  text-align: center;
  border-width: 2px;
  display: inline-block;
  width: 98%;
  height: 34px !important;
  padding: 6px;
  font-size: 13px;
  border-color: $secondaryColor;
  color: $secondaryColor;
  &:hover {
    background-color: $secondaryColor;
    color: $whiteColor;
  }
}
.btn-outlined-cancle-tall {
  @extend .btn-outlined;
  height: 40px !important;
  border-color: $secondaryColor;
  text-align: center;
  border-width: 2px;
  display: block;
  padding: 10px;
  font-size: 13px;
  border-color: $secondaryColor;
  color: $secondaryColor;
  &:hover {
    background-color: $secondaryColor;
    color: $whiteColor;
  }
}
.btn-outlined-reserve {
  @extend .btn-outlined;
  text-align: center;
  border-width: 2px;
  display: inline-block;
  width: 98%;
  height: 34px !important;
  padding: 6px;
  font-size: 13px;
  border-color: $primaryColor;
  color: $primaryColor;
  &:hover {
    background-color: $primaryColor;
    color: $whiteColor;
  }
}
.btn-outlined-reserve-tall {
  @extend .btn-outlined;
  height: 40px !important;
  border-color: $secondaryColor;
  text-align: center;
  border-width: 2px;
  display: block;
  padding: 10px;
  font-size: 13px;
  border-color: $primaryColor;
  color: $primaryColor;
  &:hover {
    background-color: $primaryColor;
    color: $whiteColor;
  }
}
.btn-fiiled {
  color: black;
  padding: 7px 5px;
  border-radius: 3px;

  background-color: $whiteColor;
  &:hover {
    color: black;
  }
}
.btn-buy-action {
  background-color: $secondaryColor;
  border-radius: 20px;
  padding: 3px;
  width: 80%;
  margin: 0 auto;
  border: 2px solid $secondaryColor;
  a,
  i {
    color: $whiteColor;
  }
  &:hover {
    background-color: $whiteColor;
    a,
    i {
      color: $secondaryColor;
    }
  }
}

.btn-encouregment {
  border-radius: 5px;
  color: white;
  background-color: #069e2c;
  border: 2px solid #069e2c;
  display: inline-block;
  width: 98%;
  margin: auto;
  height: 34px;
  padding: 3px;
  text-align: center;
  cursor: pointer;
  font-size: 13px;
  line-height: 30px;
  &:hover {
    background-color: white;
    color: #069e2c;
  }
}
.circle-encouragement {
  border: 2px solid #069e2c;
  border-radius: 50%;
  display: inline-block;
  height: 17px;
  width: 17px;
  text-align: center;
  color: #069e2c;
  cursor: pointer;
}
.without-focus {
  &:focus-within {
    border-bottom: none !important;
  }
}
select.form-input {
  background-color: white;
}
.form-input-border {
  display: flex;
  justify-content: stretch;
  border: 2px solid $pillGray;
  border-radius: 15px;
  height: 50px;
  padding: 0 !important;
  svg {
    color: $pillTextGray;
    flex-basis: 32px;
    margin: 0 7px;
  }
  align-items: center;
  background-color: white;
}

.form-input-border-select {
  display: flex;
  justify-content: stretch;
  border: 2px solid $pillGray;
  border-radius: 15px;
  height: 50px;
  padding: 5px !important;
  svg {
    color: $pillTextGray;
    flex-basis: 32px;
    margin: 0 7px;
  }
  align-items: center;
  background-color: white;
}
.form-input {
  border: none;
  height: 100%;
  width: 80%;
  &:focus {
    outline: none;
    border: none;
  }
}

.border-pill,
.border-pill-lg {
  border: 1px solid $pillGray;
  border-radius: 5px;
}
.border-right-pill {
  border-right: 1px solid $pillGray;
}
.border-bottom-black {
  border-bottom: 1px solid black;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
.clear {
  clear: both;
}
.visible-xs {
  display: none;
}
.exit-form {
  border: 2px solid $secondaryColor;
  border-radius: 3px;
  color: $secondaryColor;
  cursor: pointer;
}
.exit-form-circle {
  border: 2px solid $secondaryColor;
  border-radius: 50%;
  display: inline-block;
  height: 18px;
  width: 18px;
  text-align: center;
  color: $secondaryColor;
  cursor: pointer;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #525965;
}
.rotate-y-180 {
  transform: rotateY(180deg);
}
.rotate-upsidedown-reverse {
  transform: rotateZ(40deg) scale(1, 1);
  //     transform:rotateZ(-40deg) scale(-1 , 1);
}
.popup-content-container {
  padding: 20px 30px;
  .popup-heading {
    padding: 10px 0;
    text-align: right;
    span {
      margin: 5px 0;
      padding: 4px 8px 0px 8px;
      display: inline-block;
    }
  }
}
.start-box {
  color: #fb9900;
}
.text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%; // some width
}
.icon {
  min-width: 38px;
  width: auto;
  height: 38px;
  background-color: $pillSecondaryColor;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  padding: 2px;
  svg:first {
    font-size: 20px;
  }
}
.two-icon {
  @extend .icon;
  padding: 2px 10px;
  svg:nth-child(2) {
    margin-right: 15px;
  }
}
.card {
  background-color: white;
  padding: 15px;
  textarea {
    border: 2px solid $pillGray;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    resize: none;
    &:focus {
      outline: none;
    }
  }
}
