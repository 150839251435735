.product-img{
    position: relative;
    padding: 3px;
    border: 1px solid black;
    width: 250px;
    height: 250px;
    img{
        width: 100%;
        height: 100%;
    }
    svg{
        position: absolute;
        top: 5px;
        right: 5px;
    }
}